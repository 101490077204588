// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --qa` replaces `environment.ts` with `environment.qa.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    API_URL: "/oauth",
    env: "qa",
    http_proxy: "http://inetgw.aa.com:9090",
    production: false,
    profile: "main"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
