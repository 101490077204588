import { Component, OnInit } from "@angular/core";
import { AppService } from "../services/app.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { PingService } from "src/services/ping.service";
import { PingConfig } from "./ping.config";


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    title = "aa-ct-loyalty-login-service";
    currentUrl: string = null;
    loadContent = true;

    public constructor(
        private appService: AppService,
        private router: Router,
        private route: ActivatedRoute,
        private pingService: PingService,
        public pingConfig: PingConfig,
    ) {}

    public get isLoading(): boolean {
        return this.appService.isLoading;
    }

    ngOnInit() {
        history.replaceState(null, '', location.href);

        const routeListener = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe(async (event: NavigationEnd) => {
            if(window.location.href.indexOf('miniMenu') > -1) {
                return;
            }
            let queryParams: any = this.route.snapshot.queryParams;
            if(!queryParams || !queryParams.flowId) {
                this.loadContent = false;
                await this.router.navigate(["/error"], { skipLocationChange: true });
                return;
            }
            this.pingService.saveFlowId(queryParams.flowId);
            this.pingService.processCurrentStatus();
            routeListener.unsubscribe();
        });
    }
}