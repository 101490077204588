import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { PingConfig } from "src/app/ping.config";
import { environment } from "../environments/environment";
import { AppService } from "./app.service";

@Injectable()
export class PingService {

    private USERNAME_PASSWORD_REQUIRED_URL = null;
    private UPDATE_PASSWORD_REQUIRED_URL = null;
    private AUTHENTICATE_URL = "null";
    private COMPLETE_AUTHENTICATE_URL = "null";
    private OTP_URL = "null";
    private USERNAME_PASSWORD_REQUIRED_STATUS = "LOYALTY_CREDENTIALS_REQUIRED";
    private UPDATE_PASSWORD_REQUIRED_STATUS = "PASSWORD_UPDATE_REQUIRED";
    private AUTHENTICATION_REQUIRED = "AUTHENTICATION_REQUIRED";
    private OTP_REQUIRED_STATUS = "OTP_REQUIRED";
    private MFA_COMPLETED_STATUS = "MFA_COMPLETED";
    private RESUME = "RESUME";
    private flowId = null;

    public constructor(
        private httpClient: HttpClient,
        private router: Router,
        private pingConfig: PingConfig,
        private appService: AppService
    ) {
    }

    public saveFlowId(flowId: string) {
        this.flowId = flowId;
    }

    public getFlowId() {
        return this.flowId;
    }

    public processCurrentStatus(): void {
        if (!this.flowId) return;
        if (this.pingConfig.userConfig.status === this.USERNAME_PASSWORD_REQUIRED_STATUS) {
            this.USERNAME_PASSWORD_REQUIRED_URL = this.pingConfig.userConfig._links.submitLoyaltyCredentials.href;
            this.router.navigateByUrl(`login?flowId=${this.flowId}&locale=${this.pingConfig.userConfig.locale}&QMSession=${this.pingConfig.userConfig.qmSession}&QMUser=${this.pingConfig.userConfig.qmUser}`);
            return;
        }
        if (this.pingConfig.userConfig.status === this.UPDATE_PASSWORD_REQUIRED_STATUS) {
            this.appService.isLoading = false;
            this.UPDATE_PASSWORD_REQUIRED_URL = this.pingConfig.userConfig._links.submitPasswordUpdate.href;
            this.router.navigate(["createNewPassword"], { skipLocationChange: true });
            return;
        }
        if (this.pingConfig.userConfig.status === this.AUTHENTICATION_REQUIRED) {
            this.AUTHENTICATE_URL = this.pingConfig.userConfig._links.authenticate.href;
            this.router.navigate(['/mfaPage'], { skipLocationChange: true });
            return;
        }
        if (this.pingConfig.userConfig.status === this.OTP_REQUIRED_STATUS) {
            this.OTP_URL = this.pingConfig.userConfig._links.checkOtp.href;
            return;
        }
        if (this.pingConfig.userConfig.status === this.MFA_COMPLETED_STATUS) {
            this.COMPLETE_AUTHENTICATE_URL = this.pingConfig.userConfig._links.continueAuthentication.href;
            return;
        }
    }

    public authenticate<T>(): Observable<T> {

        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true
        ) {
            proxied.apply(this, [method, url, async]);
        };
        return this.httpClient.post<T>(`${this.AUTHENTICATE_URL}`,
            {
            },
            {
                headers: new HttpHeaders({
                    "X-XSRF-Header": "PingFederate",
                    "Content-Type": "application/vnd.pingidentity.authenticate+json"
                }),
                withCredentials: true
            });
    }

    public resendOtpCode<T>(deviceId: string): Observable<T> {

        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true
        ) {
            proxied.apply(this, [method, url, async]);
        };
        return this.httpClient.post<T>(`${this.AUTHENTICATE_URL}`,
            {
                "deviceRef": {
                    "id": deviceId
                }
            },
            {
                headers: new HttpHeaders({
                    "X-XSRF-Header": "PingFederate",
                    "Content-Type": "application/vnd.pingidentity.selectDevice+json"
                }),
                withCredentials: true
            });
    }

    public completeAuthentication<T>(): Observable<T> {
        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true
        ) {
            proxied.apply(this, [method, url, async]);
        };
        return this.httpClient.post<T>(`${this.COMPLETE_AUTHENTICATE_URL}`,
            {
            },
            {
                headers: new HttpHeaders({
                    "X-XSRF-Header": "PingFederate",
                    "Content-Type": "application/vnd.pingidentity.continueAuthentication+json"
                }),
                withCredentials: true
            });
    }

    public submitOneTimePassword<T>(otp: number): Observable<T> {

        const proxied = XMLHttpRequest.prototype.open;

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true,
            otp?: string,
        ) {
            proxied.apply(this, [method, url, async]);
        };
        return this.httpClient.post<T>(`${this.OTP_URL}`,
            {
                otp
            },
            {
                headers: new HttpHeaders({
                    "X-XSRF-Header": "PingFederate",
                    "Content-Type": "application/vnd.pingidentity.checkOtp+json"
                }),
                withCredentials: true
            });
    }


    public submitCredentials<T>(tempUserName: string, password: string): Observable<T> {

        const proxied = XMLHttpRequest.prototype.open;
        tempUserName = tempUserName.trim();
        password = password.trim();

        XMLHttpRequest.prototype.open = function (
            method: "delete" | "get" | "post" | "put",
            url: string,
            async = true,
            user?: string,
            password?: string
        ) {
            proxied.apply(this, [method, url, async, user, password]);
        };
        return this.httpClient.post<T>(`${this.USERNAME_PASSWORD_REQUIRED_URL}?action=submitLoyaltyCredentials`,
            {
                username: tempUserName,
                password: password
            },
            {
                headers:  new HttpHeaders({
                    "X-XSRF-Header": "PingFederate",
                    "Content-Type": "application/vnd.pingidentity.authenticate+json"
                }),
                withCredentials: true
            });
    }

    public updatePassword<T>(password: string | readonly string[], confirmPassword: string | readonly string[]): Observable<T> {
    const proxied = XMLHttpRequest.prototype.open;

                XMLHttpRequest.prototype.open = function (
                    method: "delete" | "get" | "post" | "put",
                    url: string,
                    async = true,
                    user?: string,
                    password?: string
                ) {
                    proxied.apply(this, [method, url, async, user, password]);
                };
                return this.httpClient.post<T>(`${this.UPDATE_PASSWORD_REQUIRED_URL}?action=submitPasswordUpdate`,
                    {
                        password: password,
                        confirmPassword: confirmPassword
                    },
                    {
                        headers: new HttpHeaders({
                            "X-XSRF-Header": "PingFederate",
                            "Content-Type": "application/vnd.pingidentity.authenticate+json"
                        }),
                        withCredentials: true
                    });
    }
}
